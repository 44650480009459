import styled from 'styled-components'

export default styled.div`
  .dashboard-top-children-wrapper {
    width: 100%;
    height: calc(100% - 30px); /* 30px from dashboard top header */
    display: flex;
    &.flex-column {
      .camera-detail-card {
        margin-bottom: 20px;
      }
    }
  }
`
