import React from 'react'
import PropTypes from 'prop-types'

import DashboardTopStyled from './styledComponent'

class DashboardTop extends React.Component {
  render() {
    let childenWrapperClassName = this.props.alignMent === 'vertical' ? ' flex-column' : ' '
    return (
      <DashboardTopStyled>
        <div className={'dashboard-top-children-wrapper' + childenWrapperClassName}>{this.props.children}</div>
      </DashboardTopStyled>
    )
  }
}

DashboardTop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  alignMent: PropTypes.string
}

export default DashboardTop
